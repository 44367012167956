import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import firebase from 'firebase/app';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import MsSymbol from '../../assets/mssymbol.svg';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { PageLayout } from '../layout';
import { Alert } from '@material-ui/lab';
import Collapse from '@material-ui/core/Collapse';
import * as EmailValidator from 'email-validator';
import { Box, CircularProgress, Paper, useTheme } from '@mui/material';
import { displayName } from 'qrcode.react';

const useStyles = makeStyles((theme) => ({
   paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
   },
   avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
   },
   form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
   },
   submit: {
      margin: theme.spacing(3, 0, 2),
   },
   mssubmit: {
      margin: theme.spacing(1, 0, 2),
      borderWidth: 1,
      borderColor: '#8C8C8C',
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
   },
   mssignin: {
      margin: theme.spacing(0, 2),
      height: '34px',
   },
}));

const SignUpFormBase = (props) => {
   const classes = useStyles();
   const theme = useTheme();
   const roles = {};
   const [error, setError] = useState(false);
   const [helperText, setHelperText] = useState('');
   const [errorMessage, setErrorMessage] = useState('');
   const [firstName, setFirstName] = useState('');
   const [lastName, setLastName] = useState('');
   const [companyName, setCompanyName] = useState('');
   const [email, setEmail] = useState('');
   const [firstNameError, setFirstNameError] = useState(false);
   const [firstNameHelperText, setFirstNameHelperText] = useState('');
   const [lastNameError, setLastNameError] = useState(false);
   const [lastNameHelperText, setLastNameHelperText] = useState('');
   const [companyNameError, setCompanyNameError] = useState(false);
   const [companyNameHelperText, setCompanyNameHelperText] = useState('');
   const [emailError, setEmailError] = useState(false);
   const [emailHelperText, setEmailHelperText] = useState('');
   const [password, setPassword] = useState('');
   const [password2, setPassword2] = useState('');
   const [allowExtraEmails, setAllowExtraEmails] = useState(false);
   const [checked, setChecked] = useState(false);
   const [termsAndPrivacy, setTermsAndPrivacy] = useState(true);
   const [termsAndPrivacyError, setTermsAndPrivacyError] = useState(false);
   const [termsAndPrivacyHelp, setTermsAndPrivacyHelp] = useState('');
   const [disabled, setDisabled] = useState(false);

   const Toast = () => {
      return (
         <div>
            <Collapse in={checked} collapsedHeight={0}>
               <Alert severity="error">
                  <strong>Error - </strong>
                  {errorMessage}
               </Alert>
            </Collapse>
         </div>
      );
   };

   async function signUpUser(domain) {
      //create a new authenticated user and return the uid
      var emailDomain = email.split('@');
      const userId = await props.firebase
         .doCreateUserWithEmailAndPassword(email, password)
         .then((authUser) => {
            var newUser = firebase.auth().currentUser;
            newUser.updateProfile({
               displayName: firstName + ' ' + lastName,
            });
            return authUser.user.uid;
         });
      //save the other fields to firestore
      var newCompanyKey = await props.firebase.companies().doc();
      var newLocationKey = await props.firebase.locations().doc();
      // var newGroupKey = await props.firebase.groups(newLocationKey).doc();
      return (
         props.firebase.user(userId).set(
            {
               cid: `${newCompanyKey.id}`,
               lid: [`${newLocationKey.id}`],
               locationAdmin: [`${newLocationKey.id}`],
               gid: [],
               name: {
                  firstName,
                  lastName,
               },
               email,
               roles,
               marketing: {
                  optIn: allowExtraEmails,
               },
            },
            { merge: true },
         ),
         props.firebase.company(newCompanyKey.id).set({
            companyName,
            profile: {
               creationDate: new Date(),
               termsAgreed: new Date(),
            },
            locations: {
               [`${newLocationKey.id}`]: companyName,
            },
            subscription: {
               plan: 'essential',
               planStartDate: new Date(),
            },
            domains: {
               used: [emailDomain[1].toLowerCase()],
               allowed: [],
            },
            totalPeople: 1,
         }),
         props.firebase.db.doc(`company/${newCompanyKey.id}/indexes/people`).set({
            [userId]: {
               email,
               name: {
                  firstName: `${firstName}`,
                  lastName: `${lastName}`,
               },
            },
         }),
         props.firebase
            .companyPeople(newCompanyKey.id)
            .doc(`${userId}`)
            .set({
               name: {
                  firstName,
                  lastName,
               },
               email,
               roles,
               locationAdmin: [`${newLocationKey.id}`],
               lid: [`${newLocationKey.id}`],
               gid: [],
               cid: `${newCompanyKey.id}`,
               status: {
                  statusId: 'user1',
               },
            }),
         props.firebase.location(newLocationKey.id).set({
            name: companyName,
            profile: {
               creationDate: new Date(),
            },
            rules: {
               totalMaxCapacity: 100,
            },
            capacity: {
               unit: 'people',
            },
            people: 1,
            cid: `${newCompanyKey.id}`,
            locationAdmins: [`${userId}`],
            permissions: {
               editPrevious: {
                  admin: 'alwaysOn',
                  user: 'defaultOff',
               },
            },
            setup: {
               capacity: false,
               people: false,
               status: false,
               done: false,
            },
         }),
         props.firebase.db.doc(`location/${newLocationKey.id}/indexes/people`).set({
            [userId]: {
               name: {
                  firstName: `${firstName}`,
                  lastName: `${lastName}`,
               },
            },
         }),
         // props.firebase
         // .locationPerson(newLocationKey.id, userId)
         // .set({
         //   name : {
         //     firstName,
         //     lastName,
         //   },
         //   email,
         //   roles,
         //   lid: [`${newLocationKey.id}`],
         //   status : {
         //     statusId: "user1",
         //   },
         // }),
         props.firebase.saveDomain(newCompanyKey.id).set({
            active: true,
            cid: `${newCompanyKey.id}`,
            usedDomains: [emailDomain[1].toLowerCase()],
            allowedDomains: [],
         })
      );
   }

   function handleSubmit(event) {
      event.preventDefault();

      roles[ROLES.ADMIN] = ROLES.ADMIN;
      //validate form
      var valid = true;
      if (firstName === '') {
         setFirstNameError(true);
         setFirstNameHelperText('required');
         valid = false;
      }
      if (lastName === '') {
         setLastNameError(true);
         setLastNameHelperText('required');
         valid = false;
      }
      if (companyName === '') {
         setCompanyNameError(true);
         setCompanyNameHelperText('required');
         valid = false;
      }

      var emailValid = EmailValidator.validate(email); // true
      if (emailValid === false) {
         setEmailError(true);
         setEmailHelperText('You must enter a vaild email address');
         valid = false;
      }
      if (password !== password2) {
         setError(true);
         setHelperText('Passwords must be identical');
         valid = false;
      }

      if (termsAndPrivacy === false) {
         setTermsAndPrivacyError(true);
         setTermsAndPrivacyHelp(
            'You must accept our General Subscription Terms & Conditions, End User License and Privacy Policy to sign up to our service',
         );
         valid = false;
      }
      if (valid === true) {
         var domain = email.split('@');
         domain = domain[1];
         //check if domain has already signed up
         var domainCheck = {};
         var t = props.firebase.checkDomain(domain);
         t.get().then(function (result) {
            domainCheck = !result.empty;
            var present = [
               'gmail.com',
               'yahoo.co.uk',
               'yahoo.com',
               'googlemail.com',
               'hotmail.com',
               'hotmail.co.uk',
               'aol.com',
               'outlook.com',
            ].includes(domain);

            if (domainCheck === false || present === true) {
               setDisabled(true);
               signUpUser(domain)
                  .then(() => {
                     return props.firebase.doSendEmailVerification();
                  })
                  .then(() => {
                     var sendInitialWelcomeEmail = firebase
                        .functions()
                        .httpsCallable('sendInitialWelcomeEmail-sendInitialWelcomeEmail');
                     return sendInitialWelcomeEmail({
                        companyName,
                        name: { firstName, lastName },
                        email,
                        type: 'Email',
                     });
                  })
                  .then(() => {
                     props.history.push(ROUTES.DASHBOARD);
                     setDisabled(false);
                  })
                  .catch((error) => {
                     console.warn(error);
                     setDisabled(false);
                     setErrorMessage(error.message);
                     setChecked((prev) => !prev);
                  });
            } else if (domainCheck === true) {
               setErrorMessage(
                  'Oops, it looks like someone from your organisation has already signed up.',
               );
               setChecked((prev) => !prev);
            }
         });
      }
   }

   const toggleOptIn = (event) => {
      setAllowExtraEmails(event.target.checked);
   };
   const toggleTerms = (event) => {
      setTermsAndPrivacy(event.target.checked);
      setTermsAndPrivacyError(false);
      setTermsAndPrivacyHelp('');
   };

   return (
      <PageLayout sx={{ display: 'flex', justifyContent: 'center' }}>
         <Paper
            sx={{
               mt: theme.spacing(1),
               display: 'flex',
               flexDirection: 'column',
               alignItems: 'center',
               p: theme.spacing(4),
               minWidth: 300,
               maxWidth: 450,
            }}
         >
            <Box maxWidth={450}>
               <div className={classes.paper}>
                  <Toast />
                  <Avatar className={classes.avatar}>
                     <LockOutlinedIcon />
                  </Avatar>
                  <Typography component="h1" variant="h5" gutterBottom>
                     Create a new company account
                  </Typography>
                  <Button
                     fullWidth
                     href={ROUTES.AUTH}
                     variant="outlined"
                     className={classes.mssubmit}
                     style={{
                        maxHeight: '41px',
                        minHeight: '41px',
                        borderWidth: 1,
                        borderColor: '#8C8C8C',
                     }}
                  >
                     <div
                        style={{
                           display: 'flex',
                           alignItems: 'center',
                           flexWrap: 'wrap',
                        }}
                     >
                        <Icon className={classes.mssignin}>
                           <img src={MsSymbol} />
                        </Icon>
                        <Typography>Sign up with Microsoft</Typography>
                     </div>
                  </Button>
                  <Typography component="p" variant="h6" gutterBottom>
                     Or
                  </Typography>
                  <Divider style={{ width: '100%', marginBottom: '8px' }} />
                  <Typography variant="body1">
                     Sign up with your work email address
                  </Typography>
                  <form className={classes.form} onSubmit={handleSubmit} noValidate>
                     <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                           <TextField
                              autoComplete="fname"
                              name="firstName"
                              variant="outlined"
                              required
                              fullWidth
                              error={firstNameError}
                              helperText={firstNameHelperText}
                              id="firstName"
                              label="First Name"
                              value={firstName}
                              onInput={(e) => {
                                 setFirstName(e.target.value);
                                 setFirstNameError(false);
                                 setFirstNameHelperText('');
                              }}
                              autoFocus
                           />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                           <TextField
                              variant="outlined"
                              required
                              fullWidth
                              error={lastNameError}
                              helperText={lastNameHelperText}
                              id="lastName"
                              label="Last Name"
                              name="lastName"
                              autoComplete="lname"
                              value={lastName}
                              onInput={(e) => {
                                 setLastName(e.target.value);
                                 setLastNameError(false);
                                 setLastNameHelperText('');
                              }}
                           />
                        </Grid>
                        <Grid item xs={12}>
                           <TextField
                              variant="outlined"
                              required
                              fullWidth
                              error={companyNameError}
                              helperText={companyNameHelperText}
                              id="companyName"
                              label="Company Name"
                              name="companyName"
                              value={companyName}
                              onInput={(e) => {
                                 setCompanyName(e.target.value);
                                 setCompanyNameError(false);
                                 setCompanyNameHelperText('');
                              }}
                           />
                        </Grid>
                        <Grid item xs={12}>
                           <TextField
                              variant="outlined"
                              required
                              fullWidth
                              id="email"
                              label="Company Email Address"
                              name="email"
                              value={email}
                              onInput={(e) => {
                                 setEmail(e.target.value);
                                 setEmailError(false);
                                 setEmailHelperText('');
                              }}
                              error={emailError}
                              helperText={emailHelperText}
                              autoComplete="email"
                           />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                           <TextField
                              variant="outlined"
                              required
                              fullWidth
                              name="password"
                              label="Password"
                              type="password"
                              id="password"
                              autoComplete="current-password"
                              value={password}
                              onInput={(e) => setPassword(e.target.value)}
                           />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                           <TextField
                              variant="outlined"
                              required
                              fullWidth
                              error={error}
                              name="password2"
                              label="Confirm Password"
                              type="password"
                              id="password2"
                              value={password2}
                              helperText={helperText}
                              onInput={(e) => {
                                 setPassword2(e.target.value);
                                 setError(false);
                                 setHelperText('');
                              }}
                           />
                        </Grid>
                        <Grid item xs={12}>
                           {/* <FormControl>
                              <FormLabel component="legend">Marketing</FormLabel>
                              <FormControlLabel
                                 control={
                                    <Checkbox
                                       checked={allowExtraEmails}
                                       onChange={toggleOptIn}
                                       color="primary"
                                    />
                                 }
                                 label="I want to receive inspiration, marketing promotions and updates via email."
                              />
                           </FormControl> */}
                        </Grid>
                        <Grid item xs={12}>
                           {/* <FormControl
                              required
                              error={termsAndPrivacyError}
                              component="fieldset"
                           >
                              <FormLabel component="legend">Agreement</FormLabel>
                              <FormControlLabel
                                 control={
                                    <Checkbox
                                       checked={termsAndPrivacy}
                                       onChange={toggleTerms}
                                       color="primary"
                                    />
                                 }
                                 label="I have read and accept the General Subscription Terms & Conditions, the End User License and the Privacy Policy below"
                              />
                              <FormHelperText>{termsAndPrivacyHelp}</FormHelperText>
                           </FormControl> */}
                           <Typography variant="body1" align="center">
                              By signing up you confirm that you agree to our{' '}
                              <Link
                                 href="https://intheoffice.io/legal/general-subscription-terms-and-conditions"
                                 target="_blank"
                              >
                                 General Subscription Terms & Conditions
                              </Link>
                              , our{' '}
                              <Link
                                 href="https://intheoffice.io/legal/end-user-agreement"
                                 target="_blank"
                              >
                                 End User Agreement
                              </Link>
                              , and that you have read our{' '}
                              <Link
                                 href="https://intheoffice.io/legal/privacy-policy"
                                 target="_blank"
                              >
                                 Privacy Policy
                              </Link>
                              .
                           </Typography>
                        </Grid>
                     </Grid>
                     <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                     >
                        Sign Up
                     </Button>
                     <Grid container justify="flex-end">
                        <Grid item>
                           <Link href={ROUTES.SIGN_IN} variant="body2">
                              Already have an account? Sign in
                           </Link>
                        </Grid>
                     </Grid>
                  </form>
               </div>
            </Box>
         </Paper>
      </PageLayout>
   );
};

const SignUpForm = compose(withRouter, withFirebase)(SignUpFormBase);

export default SignUpForm;
